<template lang="">
    <div>
      <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
      <div class="order-xl-1">
        <div class="card">
          <div class="card-header">
            <b-row align-v="center" slot="header" >
              <b-col cols="8">
                <h2 class="mb-0"><i class="fas fa-tasks"></i> จัดการโปรโมชัน</h2>
              </b-col>
            </b-row>
          </div>

          <div class="card-body bg-white">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
              <b-form @submit.prevent="handleSubmit(editHandler)">
                <h6 class="heading-small text-muted mb-4">ข้อมูลโปรโมชัน</h6>
                <div class="pl-lg-4">
                  <div class="mb-2 row">
                    <label class="col-lg-2 col-md-6 col-form-label label_algin_right">
                      <strong>ชื่อโปรโมชัน : </strong>
                    </label>
                    <label class="col-lg-8 col-md-6 col-form-label">{{item.name}}</label>
                  </div>
                  <hr class="my-4">
                  <b-button size="md" variant="primary" class="style_btn" style="width: 105px;" @click="onOpenProductHandler()">
                    <i class="fa fa-plus"></i> เพิ่มสินค้า
                  </b-button>
                  <div class="table-responsive padding_top_20">
                    <table class="table align-middle table-bordered">
                      <thead>
                        <tr class="text-center" >
                          <th style="min-width: 50px; width: 1px;">ลบ</th>
                          <th style="min-width: 120px; width: 1px;">รหัสสินค้า</th>
                          <th>ชื่อสินค้า</th>
                          <th style="min-width: 150px; width: 1px;">ราคาขายปัจจุบัน</th>
                          <th style="min-width: 200px; width: 1px;">ราคาโปรโมชัน</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in products" :key="`list_promotion_product_${index}`">
                          <td class="text-center checkbox_style" >
                            <button type="button"
                                    class="btn btn-danger btn-sm"
                                    @click="onDeleteSelectHandler(data, index)">
                              <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                          </td>
                          <td>{{data.code}}</td>
                          <td>{{data.name}}</td>
                          <td class="text-right">{{data.min}} - {{data.max}}</td>
                          <td>
                            <base-input :name="`price_${data.product_id}`" placeholder="ราคาโปรโมชัน" v-model="data.price" v-decimal-format :rules="{required: true}" inputClasses="form-control-sm">
                            </base-input>
                          </td>
                        </tr>
                        <tr v-if="products.length === 0" class="text-center">
                          <td colspan="5">ไม่มีข้อมูล</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr class="my-4">
                </div>
                <div class="float-right d-flex">
                  <span><router-link :to="'/promotion'" class="nav-link text-underline"> กลับ</router-link></span>
                  <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                  <b-button variant="primary" type="submit" class="style_btn" v-if="permission.actionData.flag_update"><i class="fa fa-check"></i> บันทึก</b-button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
      <dialog-manage-product @selected="onSelectedProductHandler" ref="dialogManageProduct"/>
    </div>
</template>
<script>
import NumberUtils from '../../../util/numberUtils';
import dialogManageProduct from './dialog.manage.product';
export default {
  name: 'promotion-view-page-manage-promotion',
  data () {
    return {
      permission:{
        appSlug: 'promotion',
        actionData: {}
      },
      products: [],
      item: {},
      id: '',
    }
  },
  methods: {
    async editHandler () {
      if(this.products.length==0){
        this.AlertUtils.alert('warning', "กรุณาระบุสินค้า");
        return;
      }
      const result = await this.HttpServices.putData(`/ab_promotion/putItems/${this.item.id}`, {productItems: JSON.stringify(this.products)});

      if(result&&result.status.code=="200"){
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/promotion');
        });
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
    },
    onBackHandler(){
      this.$router.push("/promotion");
    },

    /************ Product ************/
    onOpenProductHandler(){
      this.$refs.dialogManageProduct.getListItems();
      this.$bvModal.show('dialog_manage_product');
    },
    onSelectedProductHandler(s){
      const st = JSON.parse(JSON.stringify(this.products));
      for(const v of s){
        const productId = v.id;
        let flagInsert = true;
        for(const vv of st){
          if(vv.product_id==productId){
            flagInsert = false;
            break;
          }
        }
        if(flagInsert){
          v.price = '';
          v.product_id = v.id;
          if(v.min){
            v.min = NumberUtils.toDecimalFormat(v.min);
          }
          if(v.max){
            v.max = NumberUtils.toDecimalFormat(v.max);
          }

          const data = JSON.parse(JSON.stringify(v));
          this.products.push(data);
        }
      }
    },
    onDeleteSelectHandler(v, index){
      this.products.splice(index, 1);
    },
    /************ Product ************/


    async getItem(){
      const result = await this.HttpServices.getData(`/ab_promotion/${this.id}`);
      if(result&&result.status.code=="200"){
        this.item = result.data.item;
        this.products = result.data.products;
      }else{
        this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูลโปรโมชั่น`, ()=>{
          this.$router.push('/promotion');
        });
      }
    },
  },
  components: {
    dialogManageProduct
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    this.id = await this.SessionStorageUtils.getSession("page-promotion");
    await this.getItem();
  },
}
</script>
